import axios from 'axios';
import { routes } from '../../config/index';

const http = axios.create({
  baseURL: routes.tc,
  timeout: 10000,
});

const languageMapper = {
  'sr-latn': 'sr',
  'mk-latn': 'mk',
};

const getUrl = (language, companyUuid) => {
  const mappedLanguage = languageMapper[language.toLowerCase()] ?? language;
  const projectsLocale = `/translations/54625b380dfeca4340375b06a3cad539/locales/${mappedLanguage}`;
  const tenantLocale = companyUuid ? `${projectsLocale}/tenants/${companyUuid}` : projectsLocale;

  return `${tenantLocale}?includeLocaleFallback=true`;
};

export default {
  get(language, companyUuid) {
    const url = getUrl(language, companyUuid);
    return http.get(url)
      .then(response => response.data)
      .catch((err) => {
        console.warn('Error getting translations: ', err.toJSON());
        return err.toJSON();
      });
  },
};
