<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import 'moment-timezone';

export default {
  name: 'App',
  computed: {
    prematchLoaded() {
      return this.$store.state.prematch;
    },
  },
  methods: {
    calculateScrollbarWidth() {
      const e = document.createElement('div');

      Object.assign(e.style, {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        position: 'absolute',
        visibility: 'hidden',
        top: '0',
        left: '0',
      });

      document.body.appendChild(e);

      const scrollbarWidth = e.offsetWidth - e.clientWidth;

      document.body.removeChild(e);

      return scrollbarWidth;
    },
  },
  created() {
    const scrollbarWidth = this.calculateScrollbarWidth();
    this.$store.dispatch('setScrollbarWidth', scrollbarWidth);
  },
};
</script>

<style lang="scss"></style>
