let dataLayer = 'dataLayer';
const EVENT_CATEGORY = 'SEVEN.SMPrematch';

const attachGoogleTagManager = (gtmId, tagName = 'script', layer = 'dataLayer') => {
  if (!gtmId) return;

  dataLayer = layer;
  window[layer] = window[layer] || [];
  window[layer].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  const firstTagElement = document.getElementsByTagName(tagName)[0];
  const gtmScriptElement = document.createElement(tagName);
  const dl = layer !== 'dataLayer' ? `&l=${layer}` : '';
  gtmScriptElement.async = true;
  gtmScriptElement.type = 'text/partytown';
  gtmScriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}${dl}`;
  firstTagElement.parentNode.insertBefore(gtmScriptElement, firstTagElement);
};

const gTagMixin = {
  methods: {
    sendGTagEvent(eventAction, eventLabel) {
      const payload = {
        event: eventAction,
        event_category: EVENT_CATEGORY,
        event_label: eventLabel,
      };

      window[dataLayer].push(payload);
    },
    pushToGTM(data) {
      window[dataLayer].push(data);
    },
  },
};

export {
  attachGoogleTagManager,
  gTagMixin,
};
