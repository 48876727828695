import Vue from 'vue';
import VueRouter from 'vue-router';
import sbsmclientstore from '@nsftx/sb-sm-client-store';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    // default and root routes should be removed once we have product routes
    {
      path: '/',
      name: 'default',
      component: () => import('@/components/prematch/Prematch'),
      props: true,
      async beforeEnter(to, from, next) {
        if (sbsmclientstore) {
          sbsmclientstore(store, 'mainStore');
        }

        const importedModule = await import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch');

        if (!store.state.prematch) {
          store.registerModule('prematch', importedModule.default);
        }

        next();
      },
    },
    {
      path: '/ticket-preview/:id',
      name: 'PrematchTicketPreview',
      component: () => import('@/components/common/StandaloneTicketPreview'),
      props: true,
      async beforeEnter(to, from, next) {
        if (sbsmclientstore) {
          sbsmclientstore(store, 'mainStore');
        }

        const importedModule = await import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch');

        if (!store.state.prematch) {
          store.registerModule('prematch', importedModule.default);
        }

        next();
      },
    },
    {
      path: '/ticket-history',
      name: 'PrematchTicketHistory',
      component: () => import('@/components/common/standalone-ticket-history/StandaloneTicketHistory'),
      props: true,
      async beforeEnter(to, from, next) {
        if (sbsmclientstore) {
          sbsmclientstore(store, 'mainStore');
        }

        const importedModule = await import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch');

        if (!store.state.prematch) {
          store.registerModule('prematch', importedModule.default);
        }

        next();
      },
    },
    {
      path: '/ticket-check',
      name: 'PrematchTicketCheck',
      component: () => import('@/components/common/standalone-ticket-details/StandaloneTicketDetails'),
      props: true,
      async beforeEnter(to, from, next) {
        if (sbsmclientstore) {
          sbsmclientstore(store, 'mainStore');
        }

        const importedModule = await import(/* webpackChunkName: "prematchModule" */'@/store/modules/prematch');

        if (!store.state.prematch) {
          store.registerModule('prematch', importedModule.default);
        }

        next();
      },
    },
  ],
});

export default router;
