export default {
  desktop: {
    TicketPreview: () => import('../lutrijabosnaihercegovina/TicketPreview'),
    ThemeImporter: () => import(/* webpackChunkName: "prvafirma-themeWrap" */'./ThemeImporter'),
  },
  mobile: {
    TicketPreview: () => import('../lutrijabosnaihercegovina/TicketPreview'),
    ThemeImporter: () => import(/* webpackChunkName: "prvafirma-themeWrap" */'./ThemeImporter'),
  },
};
