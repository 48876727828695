export default {
  development: {
    appBaseUrl: 'https://dev.smprematch.7platform.net',
    api: 'https://dev-sportsbook-sm-distribution-api.nsoft.com',
    ticketApi: 'https://dev-sportsbook-sm-ticket.nsoft.com',
    distributionSocket: 'https://dev-sportsbook-sm-distribution-socket.nsoft.com',
    smGateway: 'https://dev-sb-sm-gateway.nsoft.com',
    smTicketSocket: 'https://dev-sportsbook-sm-ticket-socket.nsoft.com',
    smTicketCalculationSocket: 'https://dev-sportsbook-sm-ticket-calculator.nsoft.com',
    gravityApi: 'https://dev-gravity-service.7platform.com',
    tc: 'https://frasier.staging.de-2.nsoft.cloud',
    reporting: 'https://dev-sportsbook-sm-reporting-api.nsoft.com/api/admin/report',
    sevenApi: 'https://dev-seven-platform.7platform.com',
    liveSocket: 'https://sportsbook-public-api-dev.nsoft.com',
    scoutPlugin: 'https://staging.sports-scout.7platform.net',
    liveTickets: 'https://services-dev.7platform.info/web',
    liveCashout: 'https://sports-mts-cashout-dev.nsoft.com',
    liveCustomBet: 'https://sportsbook-public-api-dev.nsoft.com',
    prematchCashout: 'https://dev-sports-sm-cashout.nsoft.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/dev',
    integrations: 'https://int-lmt-coverage.staging.de-3.nsoft.cloud',
    oddinScript: 'https://bifrost.integration.oddin.gg/script.js',
    oddinIframe: 'https://bifrost.integration.oddin.gg',
    xtremepushApi: 'https://int-xtreme-push-notifications.staging.de-2.nsoft.cloud',
  },
  staging: {
    appBaseUrl: 'https://staging.smprematch.7platform.net',
    api: 'https://sports-sm-distribution-api.staging.de-2.nsoftcdn.com',
    ticketApi: 'https://sports-sm-ticket.staging.de-2.nsoftcdn.com',
    distributionSocket: 'https://sports-sm-distribution-socket.staging.de-2.nsoftcdn.com',
    smGateway: 'https://sports-sm-gateway.staging.de-2.nsoftcdn.com',
    smTicketSocket: 'https://sports-sm-ticket-socket.staging.de-2.nsoftcdn.com',
    smTicketCalculationSocket: 'https://sports-sm-ticket-calculator.staging.de-2.nsoftcdn.com',
    gravityApi: 'https://staging-gravity-service.7platform.com',
    tc: 'https://frasier.staging.de-2.nsoft.cloud',
    reporting: 'https://sports-sm-reporting-api.staging.de-2.nsoftcdn.com/api/admin/report',
    sevenApi: 'https://services-staging.7platform.com',
    liveSocket: 'https://sports-mts-api.staging.gb.nsoftcdn.com',
    scoutPlugin: 'https://staging.sports-scout.7platform.net',
    liveTickets: 'https://services-staging.7platform.com/web',
    liveCashout: 'https://sports-mts-cashout.staging.gb.nsoftcdn.com',
    liveCustomBet: 'https://sports-mts-api.staging.gb.nsoftcdn.com',
    prematchCashout: 'https://sports-sm-cashout.staging.de-2.nsoftcdn.com',
    prematchCustomBet: 'https://staging-sports-sm-custombet.nsoft.com/custombet',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/staging',
    integrations: 'https://int-lmt-coverage.staging.de-3.nsoft.cloud',
    oddinScript: 'https://bifrost.integration.oddin.gg/script.js',
    oddinIframe: 'https://bifrost.integration.oddin.gg',
    osaiTableTennisIntegrations: 'https://int-osai.staging.de-3.nsoft.cloud',
    xtremepushApi: 'https://int-xtreme-push-notifications.staging.de-2.nsoft.cloud',
  },
  production: {
    appBaseUrl: 'https://smprematch.7platform.net',
    api: 'https://sports-sm-distribution-api.de-2.nsoftcdn.com',
    ticketApi: 'https://sports-sm-ticket.de-2.nsoftcdn.com',
    distributionSocket: 'https://sports-sm-distribution-socket.de-2.nsoftcdn.com',
    smGateway: 'https://sports-sm-gateway.de-2.nsoftcdn.com',
    smTicketSocket: 'https://sports-sm-ticket-socket.de-2.nsoftcdn.com',
    smTicketCalculationSocket: 'https://sports-sm-ticket-calculator.de-2.nsoftcdn.com',
    gravityApi: 'https://gravity.de.nsoftcdn.com',
    tc: 'https://frasier.de-2.nsoft.cloud',
    reporting: 'https://sports-sm-reporting-api.de-2.nsoftcdn.com/api/admin/report',
    sevenApi: 'https://menhir.{dcUrlFragmentCl}.nsoftcdn.com',
    liveSocket: 'https://sports-mts-api.gb.nsoftcdn.com',
    scoutPlugin: 'https://sports-scout.7platform.net',
    liveTickets: 'https://menhir.{dcUrlFragmentCl}.nsoftcdn.com/web',
    liveCashout: 'https://sports-mts-cashout.gb.nsoftcdn.com',
    liveCustomBet: 'https://sports-mts-api.gb.nsoftcdn.com',
    prematchCashout: 'https://sports-sm-cashout.de-2.nsoftcdn.com',
    prematchCustomBet: 'https://sports-sm-custombet.nsoft.com/custombet',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production',
    integrations: 'https://int-lmt-coverage.de-3.nsoft.cloud',
    oddinScript: 'https://bifrost.oddin.gg/script.js',
    oddinIframe: 'https://bifrost.oddin.gg',
    osaiTableTennisIntegrations: 'https://int-osai.de-3.nsoft.cloud',
    xtremepushApi: 'https://int-xtreme-push-notifications.de-3.nsoft.cloud',
  },
};
