export default {
  desktop: {
    EventsOfferSportHeader: () => import('./desktop/EventsOfferSportHeader'),
    EventsTopOfferHeader: () => import('./desktop/EventsTopOfferHeader'),
    EventsBonusHeader: () => import('./desktop/EventsBonusHeader'),
    EventsTimelistHeader: () => import('./desktop/EventsTimelistHeader'),
    MarketsHeader: () => import('./desktop/MarketsHeader'),
    EventsOutcomesHeader: () => import('./desktop/EventsOutcomesHeader'),
    EventRow: () => import('./desktop/EventRow'),
    EventRowOutcomes: () => import('./desktop/EventRowOutcomes'),
    EventviewHeader: () => import('./desktop/EventviewHeader'),
    BetGroupsFavoritesButton: () => import('./desktop/BetGroupsFavoritesButton'),
    EventviewMarketsDropdown: () => import('./desktop/EventviewMarketsDropdown'),
    EventviewBody: () => import('./desktop/EventviewBody'),
    EventviewOutcome: () => import('./desktop/EventviewOutcome'),
    EventviewMarketHeader: () => import('./desktop/EventviewMarketHeader'),
    ThemeImporter: () => import(/* webpackChunkName: "topbet-themeWrap" */'./ThemeImporter'),
    BetslipMeta: () => import('./desktop/BetslipMeta'),
    BetslipSystemCheck: () => import('./common/BetslipSystemCheck'),
    Betslip: () => import('./desktop/BetslipDesktop'),
    StandaloneTicketHistoryItem: () => import('./desktop/StandaloneTicketHistoryItem'),
    StandaloneTicketHistoryHeader: () => import('./desktop/StandaloneTicketHistoryHeader'),
  },
  mobile: {
    ThemeImporter: () => import(/* webpackChunkName: "topbet-themeWrap" */'./ThemeImporter'),
    EventViewHeader: () => import('./mobile/EventViewHeader'),
    BetGroupsFavoritesButton: () => import('./mobile/BetGroupsFavoritesButton'),
    MarketOutcomes: () => import('./mobile/MarketOutcomes'),
    EventMarkets: () => import('./mobile/EventMarkets'),
    HeaderFilters: () => import('./mobile/HeaderFilters'),
    CategoryListHeader: () => import('./mobile/CategoryListHeader'),
    EventRow: () => import('./mobile/EventRow'),
    BetItems: () => import('./mobile/BetItems'),
    BetslipHeaderTop: () => import('./mobile/BetslipHeaderTop'),
    BetslipMeta: () => import('./mobile/BetslipMetaMobile'),
    BetslipSystemCheck: () => import('./common/BetslipSystemCheck'),
    BetslipSystems: () => import('./mobile/BetslipSystems'),
    StandaloneTicketHistoryItem: () => import('./mobile/StandaloneTicketHistoryItem'),
    TicketCheckBets: () => import('./mobile/TicketCheckBets'),
    TicketCheckMeta: () => import('./mobile/TicketCheckMeta'),
    TicketCheckHeader: () => import('./mobile/TicketCheckHeader'),
    TicketReofferHeader: () => import('./mobile/TicketReofferHeader'),
    TicketReofferMeta: () => import('./mobile/TicketReofferMeta'),
    TicketReofferBets: () => import('./mobile/TicketReofferBets'),
    EmptyEvents: () => import('./mobile/EmptyEvents'),
  },
};
