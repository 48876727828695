<template>
  <div class="not-found">
    404 - Component <b>{{ componentName }}</b> NOT FOUND !
  </div>
</template>

<script>
export default {
  name: 'NotFoundComponent',
  props: {
    componentName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.not-found{
  font-family: monospace;
  background: #000;
  color: #fff;
  position: relative;
  padding: 15px;
}

b {
  color: red;
}
</style>
