/* eslint-disable */

export const sirConnect = (clientId, lang) => {
  const languageMappings = {
    'sr-Latn': 'srl',
  };

  const sirUrl = `https://widgets.sir.sportradar.com/${clientId}/widgetloader`;

  (function (a, b, c, d, e, f, g, h, i) {
    a[e] || (i = a[e] = function () {
        (a[e].q = a[e].q || []).push(arguments)
      }, i.l = 1 * new Date, i.o = f,
        g = b.createElement(c), h = b.getElementsByTagName(c)[0], g.async = 1, g.src = d, g
        .setAttribute("n", e), h.parentNode.insertBefore(g, h), g.type="text/partytown"
    )
  })(window, document, "script",
    sirUrl, "SIR", {
      theme: false,
      language: languageMappings[lang] || lang,
    });
}
