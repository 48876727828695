export default {
  methods: {
    trackEvent(eventName, eventData) {
      if (this.$store.getters.config?.useGoogleTagManager ?? false) {
        this.sendGTagEvent(eventName, eventData);
      } else {
        this.sendGA4Event(eventName, eventData);
      }
    },
  },
};
