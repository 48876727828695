export default {
  desktop: {
    TicketPreview: () => import('./TicketPreview'),
    ThemeImporter: () => import(/* webpackChunkName: "lutrijabih-themeWrap" */'./ThemeImporter'),
  },
  mobile: {
    TicketPreview: () => import('./TicketPreview'),
    ThemeImporter: () => import(/* webpackChunkName: "lutrijabih-themeWrap" */'./ThemeImporter'),
  },
};
