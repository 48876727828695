import socketio from 'socket.io-client';
import uuidv4 from 'uuid/v4';
import state from '@/store/state';
import getters from '@/store/getters';
import { routes } from '../../config/index';

let sioDistribution = null;
let sioStatus = null;
let sioCalculation = null;
let companyUuid = null;
let storeDispatch = null;
let globalUserId = null;
let ticketFetchedFromStorage = false;
let routeParams = null;
let ticketConnectionInProggress = false;
let ticketConnectionAttemptNumber = 0;

const routingKey = uuidv4();

const connectionStatus = {
  sioDistribution: null,
  sioStatus: null,
  sioCalculation: null,
};
const storeGetters = {
  translate: getters.translate(state),
};

export default {
  instance(companyUuidValue, storeDispatchRef, userUuid, params) {
    storeDispatch = storeDispatchRef;
    companyUuid = companyUuidValue;
    globalUserId = userUuid;
    routeParams = params;
    this.connectDistributionSocket();
    this.connectTicketSocket(userUuid);
    this.connectTicketCalculationSocket();
  },
  connectDistributionSocket() {
    const url = routes.distributionSocket;
    const socketOptions = {
      transports: ['websocket'],
      query: `company=${companyUuid}&EIO`,
    };

    if (!sioDistribution) {
      sioDistribution = socketio(url, socketOptions);
      sioDistribution.on('connect', () => {
        storeDispatch('setSocketConnectionStatus', { sioDistribution: true });
        console.log('sioDistribution connected');
      });
      sioDistribution.on('addEvent', (message) => {
        storeDispatch('eventSocketUpdate', message.data);
      });
      sioDistribution.on('disconnect', () => {
        storeDispatch('setSocketConnectionStatus', { sioDistribution: false });
        console.log('sioDistribution disconnected');
      });
    }

    if (sioDistribution.disconnected) {
      sioDistribution.connect();
    }
  },
  connectTicketSocket(userId) {
    ticketConnectionInProggress = true;
    ticketConnectionAttemptNumber = 0;

    globalUserId = userId;
    const url = routes.smTicketSocket;
    const socketOptions = {
      transports: ['websocket'],
      query: `companyUuid=${companyUuid}&EIO`,
    };

    if (!sioStatus) {
      sioStatus = socketio(url, socketOptions);

      sioStatus.on('connect', () => {
        ticketConnectionInProggress = false;
        this.registerStatusSocket(userId || globalUserId);
        storeDispatch('setSocketConnectionStatus', { sioStatus: true });
        console.log('sioStatus connected');
      });
      sioStatus.on('disconnect', () => {
        storeDispatch('setSocketConnectionStatus', { sioStatus: false });
        console.log('sioStatus disconnected');
      });

      // Web code socket messages
      sioStatus.on('codeTemplateTicketAccepted', (response) => {
        storeDispatch('toggleWebCode', response.data);
      });
      sioStatus.on('codeTemplateTicketRejected', (response) => {
        storeDispatch('addBetslipError', response);
      });
      sioStatus.on('codeTemplateCreateTicketFailed', (response) => {
        storeDispatch('addBetslipError', response);
      });

      // sioStatus.on('ticketReOffered', (response) => {
      //   storeDispatch('addReOfferedTicket', response.data);
      // });

      // Payin socket messages
      sioStatus.on('ticketAccepted', (response) => {
        storeDispatch('ticketAccepted', response);
      });
      sioStatus.on('ticketRejected', (response) => {
        const error = ((response || {}).errors || [])[0] || {};
        const action = error.data.riskManagement.rules.length
          ? 'rebetRejectedTicket'
          : '';
        const message = _.isEmpty(error.data.riskManagement.rules) ? error.detail || state.translations.ticket_rejected || 'ticket_rejected' : '';
        const { isMobile } = state.settings;
        const { disableAutoSwitch } = state.config;

        storeDispatch('resetCustomBetCalculationSelections');

        storeDispatch('payinNotification', {
          data: error,
          status: 'rejected',
        });
        storeDispatch('updateNotifications', {
          requestUuid: error.data.ticketRequest.requestUuid,
          status: 'error',
          message,
          error,
          action,
          duration: state.config?.rebetNotificationDuration,
          actionText: storeGetters.translate('rebet_rejected_ticket'),
          data: error.data,
        });

        if (!disableAutoSwitch) {
          if (isMobile) {
            storeDispatch('handleShowUI', { name: 'Betslip' });
          } else {
            storeDispatch('setSelectedTab', 'betslip');
          }
        }
      });
      sioStatus.on('ticketCancellationSuccess', (response) => {
        console.log('Cancellation success', response);
      });
      sioStatus.on('ticketCancellationFailed', (response) => {
        console.log('Cancellation failed', response);
        storeDispatch('addBetslipError', response);
      });
      sioStatus.on('ticketAdminCancellationSuccess', (response) => {
        const notificationMessage = storeGetters.translate('ticket_cancelled');
        const hash = response.data.ticketHashes && (_.head(response.data.ticketHashes) || {}).hash;
        storeDispatch('loadLatestTickets');
        storeDispatch('addNotification', {
          type: 'notification',
          message: `${hash} ${notificationMessage}.`,
          action: '',
        });
      });
    }

    if (sioStatus.disconnected) {
      sioStatus.connect();
    }
  },
  connectTicketCalculationSocket() {
    const url = routes.smTicketCalculationSocket;
    const socketOptions = {
      transports: ['websocket'],
      query: `companyUuid=${companyUuid}&EIO`,
    };

    if (!sioCalculation) {
      sioCalculation = socketio(url, socketOptions);

      sioCalculation.on('connect', () => {
        this.registerCalculationSocket();
        storeDispatch('setSocketConnectionStatus', { sioCalculation: true });
        console.log('sioCalculation connected');
      });
      sioCalculation.on('calculateTicketResult', (message) => {
        storeDispatch('calculateTicketResult', message);
      });
      sioCalculation.on('disconnect', () => {
        storeDispatch('setSocketConnectionStatus', { sioCalculation: false });
        console.log('sioCalculation disconnected');
      });
      sioCalculation.on('registrationResult', (message) => {
        const { data } = (typeof message === 'string' ? JSON.parse(message) : message);

        if (routeParams?.source === 'arena_cloud') {
          storeDispatch('clearTicketFromStorage');
          return;
        }

        if (data && !ticketFetchedFromStorage) {
          storeDispatch('getTicketFromStorage');
          ticketFetchedFromStorage = true;
        }
      });
    }

    if (sioCalculation.disconnected) {
      sioCalculation.connect();
    }
  },
  registerStatusSocket(userId) {
    storeDispatch('saveRoutingKey', routingKey);
    const data = {
      routingKey,
    };
    const type = userId ? 'Punter' : 'Web';
    if (userId) data.punterUuid = userId;
    sioStatus.emit('registration', {
      type,
      data,
    });
  },
  registerCalculationSocket() {
    sioCalculation.emit('registration', {
      type: 'Retail',
      data: {
        deviceUuid: 'calculationDevice',
      },
    });
  },
  getCalculation(ticket) {
    sioCalculation.emit('calculateTicket', ticket);
  },
  disconnectSockets() {
    sioDistribution.disconnect();
    sioStatus.disconnect();
    sioCalculation.disconnect();
  },
  reconnectStatusSocket(userId) {
    ticketConnectionAttemptNumber += 1;

    if (ticketConnectionInProggress && ticketConnectionAttemptNumber <= 10) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.reconnectStatusSocket(userId);
      }, 100);
      return;
    }

    if (sioStatus?.connected) {
      sioStatus.disconnect();
      this.connectTicketSocket(userId);
    }
  },
  connectionStatus() {
    return connectionStatus;
  },
};
